#fade{
  animation: fadein 10s infinite;
}
@keyframes fadein {
  0% {
  opacity:0;
  }
  25%{
    opacity: 1;
  }
  95%{
  opacity:1;
  }
  100% {
  opacity:0;
  }
 }